<template>
  <q-card class="l-wrapper" flat square bordered>
    <q-scroll-area class="l-grow">
      <div class="q-gutter-y-md q-pa-md">
        <div>
          <q-btn
            flat
            dense
            label="Back"
            icon="arrow_back"
            @click="router.go(-1)"
          ></q-btn>
        </div>
        <div class="">
          <label class="text-bold"> Nama User :</label>
          <q-input outlined v-model="selTest.username" readonly></q-input>
        </div>
        <div class="">
          <label class="text-bold">
            <span class="text-red">*</span> Nama Item :</label
          >
          <q-input outlined v-model="selTest.item_name"></q-input>
        </div>
        <div class="">
          <label class="text-bold">
            <span class="text-red">*</span> Nama Suplier :</label
          >
          <q-input outlined v-model="selTest.supplier_name"></q-input>
        </div>
        <div class="">
          <label class="text-bold">
            <span class="text-red">*</span> Deadline :</label
          >

          <q-input outlined v-model="selTest.deadline" readonly mask="date">
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxy"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date v-model="selTest.deadline" :options="optionsFn">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>
        <div class="">
          <label class="text-bold"> Request Note :</label>
          <q-input outlined v-model="selTest.note" type="textarea"></q-input>
        </div>
        <div class="text-red text-italic">* Wajib Diisi</div>
      </div>
      <q-card-actions class="q-pa-none" align="stretch">
        <q-btn
          unelevated
          v-if="route.params.id == '-1'"
          type="submit"
          color="primary"
          class="full-width no-border-radius"
          label="DAFTARKAN TESTING"
          @click.prevent="saveTest"
        ></q-btn>
        <q-btn
          unelevated
          v-else
          type="submit"
          color="primary"
          class="full-width no-border-radius"
          label="SIMPAN PERUBAHAN"
          @click.prevent="saveUpdate"
        ></q-btn>
      </q-card-actions>
    </q-scroll-area>
  </q-card>
</template>

<script>
import { computed, reactive, ref, inject } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import { useRoute, useRouter } from "vue-router";
import { useQuasar } from "quasar";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const $q = useQuasar();
    let $http = inject("$http");

    let curUser = ref({});

    onMounted(async () => {
      curUser.value = JSON.parse(JSON.stringify(route.meta.user));
      if (route.params.id == -1) {
        selTest.value.id_user = curUser.value.id;
        selTest.value.username = curUser.value.username;
      } else {
        $q.loading.show({ message: "mohon tunggu..." });

        await getTestDetail();
        $q.loading.hide();
      }
    });

    let getTestDetail = async () => {
      let resp = await $http.get(`/test/detail/${route.params.id}`);
      selTest.value = resp.data;
    };

    let selTest = ref({});

    let opt = reactive({});

    let saveTest = async () => {
      try {
        let resp = await $http.post("/test/add", selTest.value);
        let notifPayload = {
          id_from: curUser.value.id,
          term: ["is_rnd_supervisor"],
          content: `Test Request Baru Telah Dibuat. Item : ${selTest.value.item_name}, Supplier : ${selTest.value.supplier_name}`,
          router_to: `/app/test/chat/${resp.data.id}`,
        };
        await $http.post(`/notification/broadcastrole`, notifPayload);
        $q.notify({
          message: "Test Request Berhasil Ditambahkan!",
          color: "positive",
        });
        selTest.value = {};
        selTest.value.id_user = curUser.value.id;
        selTest.value.username = curUser.value.username;
      } catch (error) {
        console.log("error on saving development request");
        console.log(error);
      }
    };

    let saveUpdate = async () => {
      try {
        let test = JSON.parse(JSON.stringify(selTest.value));
        test.deadline = moment(test.deadline).format("YYYY-MM-DD");
        await $http.post("/test/update", test);
        $q.notify({
          message: "Perubahan Berhasil Disimpan",
          color: "positive",
        });
        await getTestDetail();
      } catch (error) {
        console.log("error on saving development request");
        console.log(error);
      }
    };

    let optionsFn = (date) => {
      return moment(date).format("YYYY/MM/DD") >= moment().format("YYYY/MM/DD");
    };

    return {
      selTest,
      opt,
      route,
      router,
      saveTest,
      optionsFn,
      saveUpdate,
    };
  },
};
</script>
